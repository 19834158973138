import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import "./General.css";
import { ToLocaleDateString } from "_utils";
import { IfNullToText } from "_utils";
import {
  ClientService,
  ProduitInterneService,
  PersonnelService,
  MissionService,
  TypeOuvrageService,
  ResultatService,
  ReussiteService,
  FamilleAffaireService,
  RaisonsPerteService,
} from "_services";
import { BoxInformationsInline, WarningBar } from "_components";

class General extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Devis", "General");
    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "N° Devis",
          accessor: "reference",
          colSize: 2,
        },
        {
          label: "Nom du devis",
          accessor: "nom_Devis",
          type: this.props.nom_Devis == null ? "text" : null,
          colSize: 4,
        },
        {
          label: "Chargé d'affaire",
          accessor: "charge_Affaire",
          functionAppliedToValue: (value) => {
            return value ? value.nom + " " + value.prenom : "-";
          },
          type: "selectSearch",
          service: PersonnelService.getForSelectSearch,
          optionFieldToDisplay: ["nom", "prenom"],
          valueFieldToDisplay: ["nom", "prenom"],
          colSize: 2,
        },
        {
          label: "Montant HT",
          accessor: "prixVenteTotal",
          colSize: 2,
        },
      ];

      const informationsDevis = [
        {
          label: "Famille d'affaires",
          accessor: "famille_Affaire",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: this.props.devis.produit_Interne?.isPresta ?? false,
          type: "selectSearch",
          service: FamilleAffaireService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Produit interne",
          accessor: "produit_Interne",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: true,
          type: "selectSearch",
          service: ProduitInterneService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
        },
        {
          label: "Client",
          accessor: "client",
          type: "selectSearch",
          required: true,
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          functionAppliedToValue: (value) => (
            <>
              <div className="d-flex justify-content-start align-items-center">
                {value && value.nom_Client ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    className="fixWidthHover"
                    data-text={value.nom_Client}
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </>
          ),
          colSize: 12,
        },
        {
          label: "Entreprise",
          accessor: "entreprise",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          functionAppliedToValue: (value) => (
            <>
              <div className="d-flex justify-content-start align-items-center">
                {value && value.nom_Client ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    className="fixWidthHover"
                    data-text={value.nom_Client}
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </>
          ),
          colSize: 12,
        },

        {
          label: "Type mission",
          accessor: "type_Mission",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: MissionService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 6,
          required: this.props.devis.produit_Interne?.designation == "ETU",
        },
        {
          label: "Type d'ouvrage",
          accessor: "type_Ouvrage",
          type: "selectSearch",
          service: TypeOuvrageService.getAll,
          functionAppliedToValue: (value) => {
            return value ? value.signe + " " + value.type : null;
          },
          optionFieldToDisplay: ["signe", "type"],
          valueFieldToDisplay: ["signe", "type"],
          colSize: 6,
        },
        {
          label: "Consultation",
          accessor: "consultation",
          functionAppliedToValue: (value) => {
            return (
              <>
                <div className="d-flex justify-content-start align-items-center">
                  {value && value.nom_Consultation ? (
                    <a
                      href={"/consultations/" + value.id}
                      target="_blank"
                      data-text={value.nom_Consultation}
                      className="fixWidthHover"
                    >
                      {value.nom_Consultation}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </>
            );
          },
          colSize: 6,
        },
        {
          label: "International",
          accessor: "international",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 6,
        },
      ];
      const informationsCommande = [
        {
          label: "Date envoi devis",
          accessor: "date_Envoie_Devis",
          type: "date",
          colSize: 6,
        },
        this.props.devis.date_Commande_Previsionnelle2 == null
          ? {
              label: "Date cde prévi",
              accessor: "date_Commande_Previsionnelle",
              type: "date",
              colSize: 6,
              required: true,
            }
          : {
              label: "Date cde prévi",
              accessor: "date_Commande_Previsionnelle",
              colSize: 6,
              functionAppliedToValue: (value) => {
                return value ? ToLocaleDateString(value) : "-";
              },
            },
        {
          label: "Date cde prévi 2",
          accessor: "date_Commande_Previsionnelle2",
          type: "date",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(value)
              : ToLocaleDateString(
                  this.props.devis.date_Commande_Previsionnelle
                );
          },
        },
        {
          label: "Délai réalisation (en semaine)",
          accessor: "delaiRealisation",
          type: "decimalTable",
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "% réussite",
          accessor: "reussite",
          type: "selectSearch",
          optionFieldToDisplay: "valeur",
          valueFieldToDisplay: "valeur",
          required: true,
          service: ReussiteService.getAll,
          functionAppliedToValue: (value) => {
            return value ? value.valeur : null;
          },
          colSize: 6,
        },
      ];
      const informationsResultat = [
        {
          label: "Résultat",
          accessor: "resultat",
          type: "selectSearch",
          optionFieldToDisplay: "valeur",
          valueFieldToDisplay: "valeur",

          service: ResultatService.getAll,
          functionAppliedToValue: (value) => {
            return value ? value.valeur : null;
          },
          colSize: 6,
        },
        {
          label: "A la date du",
          accessor: "a_La_Date_Du",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? ToLocaleDateString(value) : "-";
          },
        },
        {
          label: "Nom concurrent",
          accessor: "nom_Concurrent",
          type: "text",
          colSize: 12,
        },
        {
          label: "Montant devis perdu",
          accessor: "montant_Devis_Perdu",
          type: "decimal",
          invalidText: "Ce n'est pas un nombre",
          handleBlur: this.props.handleChange,
          colSize: 6,
        },
        {
          label: "Raison perte",
          accessor: "raison_Perte",
          type: "selectSearch",
          optionFieldToDisplay: "raison",
          valueFieldToDisplay: "raison",
          service: RaisonsPerteService.getAll,
          functionAppliedToValue: (value) => {
            return value?.raison;
          },
          colSize: 6,
        },
      ];

      const informationsFinancieres = [
        {
          label: "Acompte ?",
          accessor: "acompte",
          type: "checkbox",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
        ...(this.props.devis?.acompte
          ? [
              {
                label: "Montant acompte à recevoir",
                accessor: "montantAcomptePrev",
                type: "decimal",
                colSize: 4,
                handleBlur: this.props.handleChange,
              },
            ]
          : []),
      ];

      const informationsCommentaire = [
        {
          label: "Commentaire",
          accessor: "commentaire",
          type: "textArea",
        },
      ];
      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Create",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(this.props.devis.date_Create) +
                  " par " +
                  IfNullToText(this.props.devis.user_Create?.nom, "inconnu") +
                  " " +
                  IfNullToText(this.props.devis.user_Create?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(this.props.devis.date_Modif) +
                  " par " +
                  IfNullToText(this.props.devis.user_Modif?.nom, "inconnu") +
                  " " +
                  IfNullToText(this.props.devis.user_Modif?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
      ];
      return (
        <>
          <div className="row ficheDevis">
            <div className="col-12 BoxInformationsGeneral">
              <BoxInformationsInline
                title="Général"
                datas={informationsGeneral}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-4 col-md-12 boxInformationsDevis">
              <BoxInformationsInline
                title="Informations sur le devis"
                datas={informationsDevis}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-4 col-md-12 boxInformationsCommande">
              <BoxInformationsInline
                title="Informations prévisionnelles"
                datas={informationsCommande}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-4 col-md-12 boxInformationsResultat">
              <BoxInformationsInline
                title="Résultat"
                datas={informationsResultat}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
            <BoxInformationsInline
              title="Informations financières"
              datas={informationsFinancieres}
              state={this.props.devis}
              stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
              service={this.props.service}
              handleChange={this.props.handleChange}
              colSize="4"
              editing={this.props.editing}
              handleEditing={this.props.handleEditing}
              handleUpdate={this.props.handleUpdate}
              NoEdition={right == "RO"}
            />
            <div className="col-12 boxInformationsCommentaire">
              <BoxInformationsInline
                title="Commentaire"
                datas={informationsCommentaire}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right == "RO"}
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-6 col-md-12 boxInformationsFiche">
              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.devis}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

General.propTypes = {
  devis: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.any,
};

export { General };
