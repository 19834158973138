import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { IsoToEnglishDate } from "_utils";
import { debounce } from "lodash";

/**
 * Génère le composant Input
 * @param {string} label, le label de l'input
 * @param {*} value, la valeur de l'input
 * @param {string} accessor, l'accesseur de l'objet lié à cet input
 * @param {function} handleChange, la fonction à appeler lors d'un changement
 * @returns le composant Input
 */
class Date extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? IsoToEnglishDate(this.props.value) : null,
      focus: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleOnChangeDebounced = debounce((evenement) => {
      this.props.handleChange?.(this.props.accessor, evenement.target.value);
    }, 300);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value)
      this.setState({
        value: this.props.value ? IsoToEnglishDate(this.props.value) : null,
      });
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onBlur() {
    this.setState({ focus: false });

    if (this.props.handleBlur)
      this.props.handleBlur(this.props.accessor, this.state.value);
  }

  handleOnChange(evenement) {
    this.setState({ value: evenement.target.value });
    this.handleOnChangeDebounced(evenement);
  }

  render() {
    return (
      <>
        {this.props.label ? (
          <div className="text-uppercase text-muted solwayFont">
            {this.props.label}
          </div>
        ) : (
          ""
        )}
        <div className="input-text input-group has-validation">
          <input
            className="form-control"
            name={this.props.accessor}
            required={this.props.required}
            value={this.state.value ? this.state.value : null}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={(event) => {
              // Pour sauvegarder la date on doit aussi la formater au format français
              this.handleOnChange(event);
            }}
            type={this.props.type}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disabled={this.props.disabled}
          />
          {this.props.showClearButton ? (
            <ButtonIcon
              id={"inputGroup" + this.props.accessor}
              smallText=""
              icon={faTimes}
              iconSize="sm"
              onClick={() => {
                this.setState({ value: null });
                this.props.handleChange(this.props.accessor, null);
              }}
              className="btn btn-danger"
              tabIndex="-1"
              style={this.props.disabled ? { display: "none" } : {}}
            ></ButtonIcon>
          ) : null}
          {this.props.showValidator ? (
            <div
              id={"validation" + this.props.accessor}
              className="invalid-feedback"
            >
              {this.props.invalidText}
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

Date.propTypes = {
  value: PropTypes.any,
  accessor: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
  invalidText: PropTypes.string,
  disabled: PropTypes.bool,
  showValidator: PropTypes.bool,
  showClearButton: PropTypes.bool,
};

Date.defaultProps = {
  required: false,
};

export { Date };
