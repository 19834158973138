import {
  //faCalendarAlt,
  faCheck,
  faCopy,
  faExclamationTriangle,
  faPen,
  faPlus,
  //faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { App } from "App";
import {
  Box,
  ButtonIcon,
  ButtonIconWithSmall,
  DataFieldColumn,
  FormWithValidation,
} from "_components";
import { Input } from "_components/Input";
import {
  CarteBancaireService,
  //ChantierService,
  ContactService,
  MaterielsChantierService,
  MotifsChantierAnnulationService,
  //MotifsHeuresChantierService,
  PeriodeService,
  PersonnelService,
  StatutChantierService,
  StatutPeriodeChantierService,
  VehiculeService,
} from "_services";
import moment from "moment";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import React, { useEffect, useState } from "react";

const getArrayOfWeekByYear = (year) => {
  if (year == undefined) return [];
  let start = moment(new Date(year, 1, 1));
  let now = moment();

  let arrayWeeks = [];
  if (now.year() == start.year()) {
    for (let i = now.isoWeek(); i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  } else {
    for (let i = 1; i < now.weeksInYear(); i++) {
      arrayWeeks.push(i);
    }
  }
  return arrayWeeks;
};

const getArrayOfYears = () => {
  let now = moment();
  let year = now.year();
  let arrayYears = [];
  for (let i = 0; i < 3; i++) {
    arrayYears.push(year + i);
  }
  return arrayYears;
};

const getColorByNow = (year, week, chantier) => {
  let now = moment();
  let start = moment(new Date(year, 1, 1));
  let color = "";
  let weekNow = now.isoWeek();
  if (now.year() > start.year() || chantier.statut.designation == "Terminé") {
    // color 1
    color = "red";
  } else if (now.year() == start.year()) {
    if (weekNow == week) {
      //color 2
      color = "green";
    } else if (weekNow > week) {
      // color 1
      color = "red";
    } else {
      // color 3
      color = "yellow";
    }
  } else {
    // color 3
    color = "yellow";
  }
  return color;
};

const BlocChantier = (props) => {
  let chantier = props.chantier;
  let produitInterne = props.affaire.produitInterne.designation;
  let [editing, setEditing] = useState(false);
  let [page, setPage] = useState(1);
  let [savedChantier, setSavedChantier] = useState(null);
  let [forceUpdateWeek, setForceUpdateWeek] = useState(() => null);
  let [forceUpdateTechs, setForceUpdateTechs] = useState(() => null);
  let [forceUpdateVehicules, setForceUpdateVehicules] = useState(() => null);
  let [forceUpdateMateriels, setForceUpdateMateriels] = useState(() => null);
  //let [isSynchronizing, setIsSynchronizing] = useState(false);
  let [changeAffected, setChangeAffected] = useState(0);

  let isEndo =
    produitInterne == "ENDO" ||
    produitInterne == "DO" ||
    produitInterne == "ETU+ENDO";
  let isCO = produitInterne == "3CO" || produitInterne == "3C+PHY";
  let isEtu =
    produitInterne == "ETU" ||
    produitInterne == "PHY" ||
    produitInterne == "M3S" ||
    produitInterne == "DIG" ||
    produitInterne == "ECG" ||
    produitInterne == "ETU+PHY";

  useEffect(() => {
    if (forceUpdateTechs != null) {
      forceUpdateTechs();
      forceUpdateVehicules();
      forceUpdateMateriels();
    }
  }, [changeAffected]);

  const rightAutre = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "GeneralAutre"
  );

  const rightGeneral = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "General"
  );

  const rightPeriode = App.RightsGuard?.current?.hasRight(
    "Chantiers",
    "Periode"
  );

  // const rightSynchro = App.RightsGuard?.current?.hasRight(
  //   "Chantiers",
  //   "SynchroOutlook"
  // );

  const serviceGetContactsByClientId = () => {
    if (props.affaire.client.id) {
      return ContactService.getByIdClient(props.affaire.client.id);
    }
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    props.update(chantier.id);
    setEditing(false);
  };

  const handleChangeUpdate = (accessor, value) => {
    props.handleChange(accessor, value, chantier.id);

    if (accessor == "anneeChantier") {
      forceUpdateWeek();
      handleChangeUpdate("semaineChantier", null);
    } else if (
      accessor == "dateDebutChantier" ||
      accessor == "nbJoursPrev" ||
      accessor == "nbNuitsPrev" ||
      accessor == "heureRDV" ||
      accessor == "heureFinChantier"
    ) {
      setChangeAffected(changeAffected + 1);
    }
  };
  const handleChangePeriodeUpdate = (accessor, value) => {
    let accessors = accessor.split(".");
    props.handleChangePeriode(accessors[1], value, chantier.id, accessors[0]);
  };

  const addNewPeriodeLocal = () => {
    let id = -1;
    if (chantier.periodeChantiers != null) {
      id = Math.min(...chantier.periodeChantiers?.map((e) => e.id - 1), -1);
    }
    props.handleAddPeriode(chantier.id, {
      id: id,
      techniciensPresents: [...chantier.techniciens],
    });
  };

  const getTechniciensProxy = (params) => {
    return PersonnelService.getTechniciens({
      ...params,
      date: chantier.dateDebutChantier,
      nbJours: (chantier.nbJoursPrev ?? 0) + (chantier.nbNuitsPrev ?? 0),
    });
  };
  const getVehiculesProxy = (params) => {
    return VehiculeService.getAllPourChantier({
      ...params,
      date: chantier.dateDebutChantier,
      nbJours: (chantier.nbJoursPrev ?? 0) + (chantier.nbNuitsPrev ?? 0),
      heureFinChantier: chantier.heureFinChantier,
      heureRDV: chantier.heureRDV,
    });
  };
  const getMaterielsProxy = (params) => {
    return MaterielsChantierService.getAllPourChantier({
      ...params,
      date: chantier.dateDebutChantier,
      nbJours: (chantier.nbJoursPrev ?? 0) + (chantier.nbNuitsPrev ?? 0),
    });
  };

  const generatePeriodesBox = () => {
    return editing ? (
      <Box
        noPaddingBottom={true}
        header={boxHeader}
        rowClass="overflow-auto"
        boxClass="col-11"
        body={
          <table className="tableChantier table table-striped table-bordered table-sm">
            <thead></thead>
            <tbody>
              <tr>
                <th className="text-nowrap ps-2">Périodes *</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.periode}
                      accessor={e.id + ".periode"}
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={PeriodeService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                ))}
                <td rowSpan={15} style={{ textAlign: "center" }}>
                  {buttonAddPeriode}
                </td>
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Date *</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.datePeriode}
                      accessor={e.id + ".datePeriode"}
                      handleChange={handleChangePeriodeUpdate}
                      type="date"
                      required={true}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                ))}
              </tr>

              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier (arf)</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={
                          e.arriveeChantier != null
                            ? e.arriveeChantier
                            : e.datePeriode?.includes("T")
                            ? e.datePeriode
                            : e.datePeriode + "T00:00"
                        }
                        accessor={e.id + ".arriveeChantier"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="datetime-local"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Rendue voie</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={
                          e.rendueVoie != null
                            ? e.rendueVoie
                            : e.datePeriode?.includes("T")
                            ? e.datePeriode
                            : e.datePeriode + "T00:00"
                        }
                        accessor={e.id + ".rendueVoie"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="datetime-local"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier(arf)</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={
                          e.departChantierArf != null
                            ? e.departChantierArf
                            : e.datePeriode?.includes("T")
                            ? e.datePeriode
                            : e.datePeriode + "T00:00"
                        }
                        accessor={e.id + ".departChantierArf"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="datetime-local"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              <tr>
                <th className="text-nowrap ps-2">Temps Global</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.tempsGlobal}
                      accessor={e.id + ".tempsGlobal"}
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={true}
                    />
                  </td>
                ))}
              </tr>

              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  {chantier.periodeChantiers?.map((e, index) => (
                    <td>
                      {index == 0 ||
                      index == chantier.periodeChantiers.length - 1 ? (
                        <Input
                          value={e.tempsBureauEtMateriel}
                          accessor={e.id + ".tempsBureauEtMateriel"}
                          handleBlur={handleChangePeriodeUpdate}
                          handleChange={handleChangePeriodeUpdate}
                          type="decimal"
                          disabled={rightPeriode != "RW"}
                        />
                      ) : (
                        <div></div>
                      )}
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO || isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Temps bureau</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.tempsBureauEtMateriel}
                        accessor={e.id + ".tempsBureauEtMateriel"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              <tr>
                <th className="text-nowrap ps-2">Temps chantier</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.tempsChantier}
                      accessor={e.id + ".tempsChantier"}
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="decimal"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                ))}
              </tr>
              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  {chantier.periodeChantiers?.map((e, index) => (
                    <td>
                      {index == 0 ||
                      index == chantier.periodeChantiers.length - 1 ? (
                        <Input
                          value={e.tempsDeplacement}
                          accessor={e.id + ".tempsDeplacement"}
                          handleBlur={handleChangePeriodeUpdate}
                          handleChange={handleChangePeriodeUpdate}
                          type="decimal"
                          disabled={rightPeriode != "RW"}
                        />
                      ) : (
                        <div></div>
                      )}
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO || isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Temps déplacement</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.tempsDeplacement}
                        accessor={e.id + ".tempsDeplacement"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO || isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Déplacement nuit</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.isDeplaNuit}
                        accessor={e.id + ".isDeplaNuit"}
                        handleChange={handleChangePeriodeUpdate}
                        type="checkbox"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              <tr>
                <th className="text-nowrap ps-2">Repas</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={{ value: e.repasRealises, id: e.repasRealises }}
                      accessor={e.id + ".repasRealises"}
                      handleBlur={handleChangePeriodeUpdate}
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      options={[
                        { id: 0, value: 0 },
                        { id: 1, value: 1 },
                        { id: 2, value: 2 },
                        { id: 3, value: 3 },
                      ]}
                      optionFieldToDisplay={["value"]}
                      valueFieldToDisplay={["value"]}
                      optionFieldToReturn={["value"]}
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                ))}
              </tr>

              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Sondages réalisés</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.quantiteRealisee}
                        accessor={e.id + ".quantiteRealisee"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        numberOfDecimals={0}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">
                    Linéaire collecteur inspecté
                  </th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.lineaireCollecteurInspecte}
                        accessor={e.id + ".lineaireCollecteurInspecte"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">
                    Nb branchements inspectés
                  </th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.nombreBranchementInspecte}
                        accessor={e.id + ".nombreBranchementInspecte"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        numberOfDecimals={0}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">Nb contrôles compactage</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.nombreControleCompactageRealise}
                        accessor={e.id + ".nombreControleCompactageRealise"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        numberOfDecimals={0}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">Nb ouvrages testés</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.nombreOuvragesTestes}
                        accessor={e.id + ".nombreOuvragesTestes"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        numberOfDecimals={0}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Nombre essais</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.nombreEssai}
                        accessor={e.id + ".nombreEssai"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        numberOfDecimals={0}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              {isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Mètre linéaire</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      <Input
                        value={e.metreLineaire}
                        accessor={e.id + ".metreLineaire"}
                        handleBlur={handleChangePeriodeUpdate}
                        handleChange={handleChangePeriodeUpdate}
                        type="decimal"
                        disabled={rightPeriode != "RW"}
                      />
                    </td>
                  ))}
                </tr>
              ) : null}
              <tr>
                <th className="text-nowrap ps-2">Statut *</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.statut}
                      accessor={e.id + ".statut"}
                      handleChange={handleChangePeriodeUpdate}
                      type="selectSearch"
                      service={StatutPeriodeChantierService.getAll}
                      optionFieldToDisplay={["designation"]}
                      valueFieldToDisplay={["designation"]}
                      disabled={rightPeriode != "RW"}
                      required={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Commentaires</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.commentaire}
                      accessor={e.id + ".commentaire"}
                      handleChange={handleChangePeriodeUpdate}
                      type="text"
                      disabled={rightPeriode != "RW"}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Présence</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    <Input
                      value={e.techniciensPresents}
                      accessor={e.id + ".techniciensPresents"}
                      handleChange={handleChangePeriodeUpdate}
                      type="multipleSelectSearch"
                      disabled={rightPeriode != "RW"}
                      options={chantier.techniciens}
                      optionFieldToDisplay={["initiales"]}
                      valueFieldToDisplay={["initiales"]}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Jour/Nuit</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.periode?.isNuit ? "Nuit" : "Jour"}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Reliquat</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.periode?.reliquat}</td>
                ))}
              </tr>
            </tbody>
          </table>
        }
      ></Box>
    ) : (
      <Box
        noPaddingBottom={true}
        header={boxHeader}
        rowClass="overflow-auto"
        boxClass="col-11"
        body={
          <table className="tableChantier table table-striped table-bordered table-sm">
            <thead></thead>
            <tbody>
              <tr>
                <th className="text-nowrap ps-2">Périodes</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.periode?.designation}</td>
                ))}
                <td rowSpan={15} style={{ textAlign: "center" }}>
                  {buttonAddPeriode}
                </td>
              </tr>

              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Arrivée chantier (arf)</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      {e.arriveeChantier
                        ? new Date(e.arriveeChantier).toLocaleString("fr-FR")
                        : "-"}
                    </td>
                  ))}
                </tr>
              ) : null}
              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Rendue voie</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      {e.rendueVoie
                        ? new Date(e.rendueVoie).toLocaleString("fr-FR")
                        : "-"}
                    </td>
                  ))}
                </tr>
              ) : null}
              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Départ chantier (arf)</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>
                      {e.departChantierArf
                        ? new Date(e.departChantierArf).toLocaleString("fr-FR")
                        : "-"}
                    </td>
                  ))}
                </tr>
              ) : null}
              <tr>
                <th className="text-nowrap ps-2">Temps Global</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.tempsGlobal}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Temps bureau</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.tempsBureauEtMateriel}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Temps chantier</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.tempsChantier}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Temps déplacement</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.tempsDeplacement}</td>
                ))}
              </tr>
              {isCO || isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Déplacement nuit</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.isDeplaNuit ? "Nuit" : "Jour"}</td>
                  ))}
                </tr>
              ) : null}
              {isEndo ? (
                <tr>
                  <th className="text-nowrap ps-2">Sondages réalisés</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.quantiteRealisee}</td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">
                    Linéaire collecteur inspecté
                  </th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.lineaireCollecteurInspecte}</td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">
                    Nb branchements inspectés
                  </th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.nombreBranchementInspecte}</td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">Nb contrôles compactage</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.nombreControleCompactageRealise}</td>
                  ))}
                </tr>
              ) : null}
              {isCO ? (
                <tr>
                  <th className="text-nowrap ps-2">Nb ouvrages testés</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.nombreOuvragesTestes}</td>
                  ))}
                </tr>
              ) : null}
              {isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Nombre essais</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.nombreEssai}</td>
                  ))}
                </tr>
              ) : null}
              {isEtu ? (
                <tr>
                  <th className="text-nowrap ps-2">Mètre linéaire</th>
                  {chantier.periodeChantiers?.map((e) => (
                    <td>{e.metreLineaire}</td>
                  ))}
                </tr>
              ) : null}
              <tr>
                <th className="text-nowrap ps-2">Repas</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.repasRealises}</td>
                ))}
              </tr>

              <tr>
                <th className="text-nowrap ps-2">Statut</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.statut?.designation ?? "-"}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Commentaires</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.commentaire}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Présence</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>
                    {e.techniciensPresents?.map((tech) => tech.initiales + ",")}
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Jour/Nuit</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.periode?.isNuit ? "Nuit" : "Jour"}</td>
                ))}
              </tr>
              <tr>
                <th className="text-nowrap ps-2">Reliquat</th>
                {chantier.periodeChantiers?.map((e) => (
                  <td>{e.reliquat}</td>
                ))}
              </tr>
            </tbody>
          </table>
        }
      ></Box>
    );
  };

  let boxHeader = (
    <>
      <div className="col-3 align-self-center py-2">
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              backgroundColor: getColorByNow(
                chantier.anneeChantier,
                chantier.semaineChantier,
                chantier
              ),
              marginRight: "5px",
            }}
          />
          {chantier.reference}
        </div>
      </div>
      <div className="col-3 align-self-center text-center py-2 ">
        {page == 1 ? <>Général</> : <>Période</>}
      </div>
      <div className="col-3 text-end py-2">
        <ButtonIcon
          icon={faCheck}
          className="btn btn-success text-light ms-1"
          style={!editing ? { display: "none" } : { width: "42px" }}
          // onClick={() => {
          //   props.update(chantier.id);
          //   setEditing(false);
          // }}
          type="submit"
          tooltip={"Validation des modifications"}
        ></ButtonIcon>
        <ButtonIcon
          icon={faTimes}
          className="btn btn-danger text-light ms-1"
          onClick={() => {
            setEditing(false);
            props.handleResetChantier(chantier.id, savedChantier);
          }}
          style={!editing ? { display: "none" } : { width: "42px" }}
          tooltip={"Annulation des modification"}
        ></ButtonIcon>
        <ButtonIcon
          icon={faCopy}
          className="btn btn-secondary text-light ms-1"
          onClick={() => {
            props.duplicateNew(chantier.id);
          }}
          style={editing ? { display: "none" } : { width: "42px" }}
          tooltip={"Duplication"}
        ></ButtonIcon>
        <ButtonIcon
          icon={faPen}
          className="btn btn-secondary text-light ms-1"
          onClick={() => {
            setEditing(true);
            setSavedChantier({
              ...chantier,
              periodeChantiers: [...chantier.periodeChantiers],
            });
          }}
          style={editing ? { display: "none" } : { width: "42px" }}
          tooltip={"Edition"}
        ></ButtonIcon>

        {/* {isSynchronizing ? (
          <span>
            <button
              type={"button"}
              className={"solwayFont btn btn-secondary text-light ms-1"}
              style={{
                width: "42px",
              }}
              id={"loading"}
              disabled={true}
            >
              <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
            </button>
          </span>
        ) : (
          <ButtonIcon
            icon={faCalendarAlt}
            className={
              "btn text-light ms-1" +
              (chantier.isSynchroOutlook
                ? " btn-success "
                : chantier.hasBeenSynchronizedOutlook
                ? " btn-warning "
                : " btn-danger ")
            }
            onClick={() => {
              setIsSynchronizing(true);
              ChantierService.SynchroniserOutlook(chantier.id).then(() =>
                setIsSynchronizing(false)
              );
            }}
            style={
              editing || rightSynchro != "RW"
                ? { display: "none" }
                : { width: "42px" }
            }
            tooltip={"Synchronisation avec un calendrier Outlook"}
          ></ButtonIcon>
        )} */}
      </div>
    </>
  );
  let box = editing ? (
    <Box
      header={boxHeader}
      rowClass="overflow-auto"
      boxClass="col-11"
      body={
        <div>
          <div className="row">
            <div className="col">
              <div>
                <Input
                  label="Année"
                  value={chantier.anneeChantier}
                  accessor="anneeChantier"
                  handleChange={handleChangeUpdate}
                  type="selectSearch"
                  options={getArrayOfYears()}
                  required
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Semaine"
                  value={chantier.semaineChantier}
                  accessor="semaineChantier"
                  handleChange={handleChangeUpdate}
                  type="selectSearch"
                  options={getArrayOfWeekByYear(chantier.anneeChantier)}
                  setForceUpdateFunction={(callback) =>
                    setForceUpdateWeek(() => callback)
                  }
                  required={true}
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Date début"
                  value={chantier.dateDebutChantier}
                  accessor="dateDebutChantier"
                  handleChange={handleChangeUpdate}
                  type="date"
                  required={true}
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Heure arrivée bureau"
                  value={chantier.heureArriveeBureau}
                  accessor="heureArriveeBureau"
                  handleChange={handleChangeUpdate}
                  type="time"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Heure RDV"
                  value={chantier.heureRDV}
                  accessor="heureRDV"
                  handleChange={handleChangeUpdate}
                  type="time"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Lieu RDV"
                  value={chantier.lieuRDV}
                  accessor="lieuRDV"
                  handleChange={handleChangeUpdate}
                  type="text"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Heure Fin chantier"
                  value={chantier.heureFinChantier}
                  accessor="heureFinChantier"
                  handleChange={handleChangeUpdate}
                  type="time"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Statut"
                  value={chantier.statut}
                  accessor="statut"
                  handleChange={handleChangeUpdate}
                  type="selectSearch"
                  service={StatutChantierService.getAll}
                  optionFieldToDisplay={"designation"}
                  valueFieldToDisplay={"designation"}
                  required={true}
                  disabled={rightAutre != "RW"}
                />
              </div>
              {chantier.statut?.designation == "Annulé" ? (
                <div>
                  <Input
                    label="Motif annulation"
                    value={chantier.motifAnnulation}
                    accessor="motifAnnulation"
                    handleChange={handleChangeUpdate}
                    type="selectSearch"
                    service={MotifsChantierAnnulationService.getAll}
                    optionFieldToDisplay={"designation"}
                    valueFieldToDisplay={"designation"}
                    required={true}
                    disabled={rightAutre != "RW"}
                  />
                </div>
              ) : null}
            </div>
            <div className="col">
              <div>
                <Input
                  label="Contact"
                  value={chantier.contact}
                  accessor="contact"
                  handleChange={handleChangeUpdate}
                  type="selectSearch"
                  service={serviceGetContactsByClientId}
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="PP"
                  value={chantier.planPrevention}
                  accessor="planPrevention"
                  handleChange={handleChangeUpdate}
                  type="checkbox"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="ICP"
                  value={chantier.icp}
                  accessor="icp"
                  handleChange={handleChangeUpdate}
                  type="checkbox"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Listing"
                  value={chantier.listing}
                  accessor="listing"
                  handleChange={handleChangeUpdate}
                  type="checkbox"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Labo Granulo"
                  value={chantier.labo}
                  accessor="labo"
                  handleChange={handleChangeUpdate}
                  type="checkbox"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Lieu chantier"
                  value={chantier.lieuChantier}
                  accessor="lieuChantier"
                  handleChange={handleChangeUpdate}
                  type="address"
                  intituleLabel="Lieu chantier"
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="lien GPS"
                  value={chantier.lienGPS}
                  accessor="lienGPS"
                  handleChange={handleChangeUpdate}
                  type="text"
                  disabled={rightGeneral != "RW"}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <Input
                  label="Nuits prévues"
                  value={chantier.nbNuitsPrev}
                  accessor="nbNuitsPrev"
                  handleBlur={handleChangeUpdate}
                  handleChange={handleChangeUpdate}
                  type="decimal"
                  numberOfDecimals={2}
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <Input
                  label="Jours prévus"
                  value={chantier.nbJoursPrev}
                  accessor="nbJoursPrev"
                  handleBlur={handleChangeUpdate}
                  handleChange={handleChangeUpdate}
                  type="decimal"
                  numberOfDecimals={2}
                  disabled={rightGeneral != "RW"}
                />
              </div>

              <div>
                <Input
                  label="Nb sondages prévus"
                  value={chantier.nbSondagesPrev}
                  accessor="nbSondagesPrev"
                  handleBlur={handleChangeUpdate}
                  handleChange={handleChangeUpdate}
                  type="decimal"
                  numberOfDecimals={0}
                  disabled={rightGeneral != "RW"}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Nb sondages réalisés"
                  type="text"
                  value={chantier.nbSondagesRealises}
                />
              </div>
              {isCO ? (
                <div>
                  <Input
                    label="Linéaire collecteur inspecté prévisionnel"
                    value={chantier.lineaireCollecteurInspectePrev}
                    accessor={"lineaireCollecteurInspectePrev"}
                    handleBlur={handleChangeUpdate}
                    handleChange={handleChangeUpdate}
                    type="decimal"
                    disabled={rightGeneral != "RW"}
                  />
                </div>
              ) : null}
              {isCO ? (
                <div>
                  <Input
                    label="Nb branchements inspectés prévisionnel"
                    value={chantier.nombreBranchementInspectePrev}
                    accessor={"nombreBranchementInspectePrev"}
                    handleBlur={handleChangeUpdate}
                    handleChange={handleChangeUpdate}
                    numberOfDecimals={0}
                    type="decimal"
                    disabled={rightGeneral != "RW"}
                  />
                </div>
              ) : null}
              {isCO ? (
                <div>
                  <Input
                    label="Nb contrôles compactage prévisionnel"
                    value={chantier.nombreControleCompactageRealisePrev}
                    accessor={"nombreControleCompactageRealisePrev"}
                    handleBlur={handleChangeUpdate}
                    handleChange={handleChangeUpdate}
                    numberOfDecimals={0}
                    type="decimal"
                    disabled={rightGeneral != "RW"}
                  />
                </div>
              ) : null}
              {isCO ? (
                <div>
                  <Input
                    label="Nb ouvrages testés prévisionnel"
                    value={chantier.nombreOuvragesTestesPrev}
                    accessor={"nombreOuvragesTestesPrev"}
                    handleBlur={handleChangeUpdate}
                    handleChange={handleChangeUpdate}
                    numberOfDecimals={0}
                    type="decimal"
                    disabled={rightGeneral != "RW"}
                  />
                </div>
              ) : null}
            </div>
            <div className="col">
              <Input
                label="Techniciens"
                value={chantier.techniciens}
                accessor="techniciens"
                handleChange={handleChangeUpdate}
                type="multipleSelectSearch"
                service={getTechniciensProxy}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
                renderOption={(props, option, selected) => {
                  return (
                    <li {...props} key={props.key}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.occupe ? (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="me-2 text-danger"
                        />
                      ) : null}
                      {option.nom +
                        " " +
                        option.prenom +
                        " - " +
                        option.service?.sigle ?? ""}
                    </li>
                  );
                }}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateTechs(() => callback)
                }
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Véhicules"
                value={chantier.vehicules}
                accessor="vehicules"
                handleChange={handleChangeUpdate}
                type="multipleSelectSearch"
                service={getVehiculesProxy}
                optionFieldToDisplay={[
                  "produitInterne.designation",
                  "designation",
                ]}
                valueFieldToDisplay={"designation"}
                renderOption={(props, option, selected) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.occupe ? (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="me-2 text-danger"
                      />
                    ) : null}
                    {option.designation +
                      " - " +
                      (option.produitInterne?.designation ?? "") +
                      " (" +
                      option.nom +
                      ")"}
                  </li>
                )}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateVehicules(() => callback)
                }
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Preparateurs"
                value={chantier.preparateurs}
                accessor="preparateurs"
                handleChange={handleChangeUpdate}
                type="multipleSelectSearch"
                service={PersonnelService.getAll}
                optionFieldToDisplay={["nom", "prenom"]}
                valueFieldToDisplay={["nom", "prenom"]}
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Matériels"
                value={chantier.materielsChantier}
                accessor="materielsChantier"
                handleChange={handleChangeUpdate}
                type="multipleSelectSearch"
                service={getMaterielsProxy}
                optionFieldToDisplay={[
                  "produitInterne.designation",
                  "designation",
                  "num_Serie",
                ]}
                valueFieldToDisplay={["designation", "num_Serie"]}
                renderOption={(props, option, selected) => (
                  <li {...props} key={option.id}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.occupe ? (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="me-2 text-danger"
                      />
                    ) : null}
                    {(option.produitInterne?.designation ?? "") +
                      " | " +
                      option.designation +
                      (option.num_Serie ? " (" + option.num_Serie + ")" : "")}
                  </li>
                )}
                setForceUpdateFunction={(callback) =>
                  setForceUpdateMateriels(() => callback)
                }
                disabled={rightGeneral != "RW"}
              />
            </div>
            <div className="col">
              <Input
                label="Zone réservation"
                value={chantier.zoneReservation}
                accessor="zoneReservation"
                handleChange={handleChangeUpdate}
                type="text"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Période réservation"
                value={chantier.trancheDateReservation}
                accessor="trancheDateReservation"
                handleChange={handleChangeUpdate}
                type="period"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Proposition hôtel"
                value={chantier.propositionHotel}
                accessor="propositionHotel"
                handleChange={handleChangeUpdate}
                type="text"
                disabled={rightAutre != "RW"}
              />

              <Input
                label="Nom hôtel"
                value={chantier.nomHotel}
                accessor="nomHotel"
                handleChange={handleChangeUpdate}
                type="text"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Localisation hôtel"
                value={chantier.localisationHotel}
                accessor="localisationHotel"
                handleChange={handleChangeUpdate}
                type="text"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Hôtel prévenu"
                value={chantier.hotelPrevenu}
                accessor="hotelPrevenu"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Prix"
                value={chantier.prix}
                accessor="prix"
                handleChange={handleChangeUpdate}
                handleBlur={handleChangeUpdate}
                type="decimal"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Carte bancaire"
                value={chantier.cb}
                accessor="cb"
                handleChange={handleChangeUpdate}
                service={CarteBancaireService.getAll}
                type="selectSearch"
                optionFieldToDisplay={"name"}
                valueFieldToDisplay={"name"}
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="A annuler"
                value={chantier.annulation}
                accessor="annulation"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
            </div>
            <div className="col">
              <Input
                label="Montant remboursé 1"
                value={chantier.montantRembourse1}
                accessor="montantRembourse1"
                handleChange={handleChangeUpdate}
                handleBlur={handleChangeUpdate}
                type="decimal"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Montant remboursé 2"
                value={chantier.montantRembourse2}
                accessor="montantRembourse2"
                handleChange={handleChangeUpdate}
                handleBlur={handleChangeUpdate}
                type="decimal"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Supplément 1"
                value={chantier.supplement1}
                accessor="supplement1"
                handleChange={handleChangeUpdate}
                handleBlur={handleChangeUpdate}
                type="decimal"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Supplément 2"
                value={chantier.supplement2}
                accessor="supplement2"
                handleChange={handleChangeUpdate}
                handleBlur={handleChangeUpdate}
                type="decimal"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Petit déjeuner"
                value={chantier.petitDej}
                accessor="petitDej"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Petit déjeuner validé"
                value={chantier.petitDejValide}
                accessor="petitDejValide"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Soirée étape"
                value={chantier?.soireeEtape}
                accessor="soireeEtape"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Soirée étape validé"
                value={chantier?.soireeEtapeValide}
                accessor="soireeEtapeValide"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />

              <Input
                label="Parking"
                value={chantier.parking}
                accessor="parking"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
              <Input
                label="Parking valide"
                value={chantier.parkingValidé}
                accessor="parkingValide"
                handleChange={handleChangeUpdate}
                type="checkbox"
                disabled={rightGeneral != "RW"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                label="Commentaire"
                value={chantier.commentaire}
                accessor="commentaire"
                handleChange={handleChangeUpdate}
                type="textArea"
                disabled={rightGeneral != "RW"}
              />
            </div>
            <div className="col">
              <Input
                label="Commentaire hôtel"
                value={chantier.commentaireHotel}
                accessor="commentaireHotel"
                handleChange={handleChangeUpdate}
                type="textArea"
                disabled={rightAutre != "RW"}
              />
            </div>
          </div>
        </div>
      }
    ></Box>
  ) : (
    <Box
      header={boxHeader}
      rowClass="overflow-auto"
      boxClass="col-11"
      body={
        <div>
          <div className="row">
            <div className="col">
              <div>
                <DataFieldColumn
                  label="Année"
                  type="text"
                  value={chantier.anneeChantier}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Semaine"
                  type="text"
                  value={chantier.semaineChantier}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Date début"
                  type="text"
                  value={chantier.dateDebutChantier}
                  functionAppliedToValue={(value) =>
                    value ? new Date(value).toLocaleDateString("fr-FR") : "-"
                  }
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Heure arrivée bureau"
                  type="text"
                  value={chantier.heureArriveeBureau}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Heure RDV"
                  type="text"
                  value={chantier.heureRDV}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Lieu RDV"
                  type="text"
                  value={chantier.lieuRDV}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Heure fin chantier"
                  type="text"
                  value={chantier.heureFinChantier}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Statut"
                  type="text"
                  value={chantier.statut?.designation ?? "-"}
                />
              </div>
              {chantier.statut?.designation == "Annulé" ? (
                <div>
                  <DataFieldColumn
                    label="Motif annulation"
                    type="text"
                    value={chantier.motifAnnulation?.designation ?? "-"}
                  />
                </div>
              ) : null}
            </div>

            <div className="col">
              <div>
                <DataFieldColumn
                  label="Contact"
                  type="text"
                  value={chantier.contact}
                  functionAppliedToValue={(value) => {
                    return value ? (
                      <a
                        href={"/contacts/" + value.id}
                        target="_blank"
                        data-text={
                          value.nom_Contact + " " + value.prenom_Contact
                        }
                        className="fixWidthHover"
                      >
                        {value.nom_Contact + " " + value.prenom_Contact}
                      </a>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Téléphone"
                  type="text"
                  value={chantier.contact}
                  functionAppliedToValue={(value) => {
                    return value ? value.portable : "-";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="PP"
                  type="text"
                  value={chantier.planPrevention}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="ICP"
                  type="text"
                  value={chantier.icp}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Listing"
                  type="text"
                  value={chantier.listing}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Labo Granulo"
                  type="text"
                  value={chantier.labo}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Lieu chantier"
                  type="text"
                  value={chantier.lieuChantier}
                  functionAppliedToValue={(value) => {
                    console.log(value);
                    return value ? (
                      <div>
                        <div>{value.voie ?? ""}</div>
                        <div>{value.complement ?? ""}</div>
                        <div>
                          {(value.ville ?? " ") +
                            (value.cp ? ", " + value.cp : "")}
                        </div>
                        <div>{value.pays?.nom_fr ?? ""}</div>
                      </div>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Lien GPS"
                  type="text"
                  value={chantier.lienGPS}
                  functionAppliedToValue={(value) => {
                    if (value) return <a href={value}> cliquer ici</a>;
                    else return "-";
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <DataFieldColumn
                  label="Nuits prévues"
                  type="text"
                  value={chantier.nbNuitsPrev}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Jours prévus"
                  type="text"
                  value={chantier.nbJoursPrev}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Nb sondages prévus"
                  type="text"
                  value={chantier.nbSondagesPrev}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Nb sondages réalisés"
                  type="text"
                  value={chantier.nbSondagesRealises}
                />
              </div>
              {isCO ? (
                <div>
                  <DataFieldColumn
                    label="Linéaire collecteur inspecté prévisionnel"
                    value={chantier.lineaireCollecteurInspectePrev}
                    type="text"
                  />
                </div>
              ) : null}
              {isCO ? (
                <div>
                  <DataFieldColumn
                    label="Nb branchements inspectés prévisionnel"
                    value={chantier.nombreBranchementInspectePrev}
                    type="text"
                  />
                </div>
              ) : null}
              {isCO ? (
                <div>
                  <DataFieldColumn
                    label="Nb contrôles compactage prévisionnel"
                    value={chantier.nombreControleCompactageRealisePrev}
                    type="text"
                  />
                </div>
              ) : null}
              {isCO ? (
                <div>
                  <DataFieldColumn
                    label="Nb ouvrages testés prévisionnel"
                    value={chantier.nombreOuvragesTestesPrev}
                    type="text"
                  />
                </div>
              ) : null}
            </div>
            <div className="col">
              <div>
                <DataFieldColumn
                  label="Techniciens"
                  type="text"
                  value={chantier.techniciens}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.initiales}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Véhicules"
                  type="text"
                  value={chantier.vehicules}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.designation}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Préparateurs"
                  type="text"
                  value={chantier.preparateurs}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.initiales}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Matériels"
                  type="text"
                  value={chantier.materielsChantier}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.designation}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
            </div>
            <div className="col">
              <DataFieldColumn
                label="Zone réservation"
                type="text"
                value={chantier.zoneReservation}
              />
              <DataFieldColumn
                label="Période"
                type="text"
                value={chantier}
                functionAppliedToValue={(value) => {
                  if (value) {
                    let text =
                      (value.dateReservationDebut
                        ? new Date(
                            value.dateReservationDebut
                          ).toLocaleDateString("fr-FR")
                        : "") +
                      " | " +
                      (value.dateReservationFin
                        ? new Date(value.dateReservationFin).toLocaleDateString(
                            "fr-FR"
                          )
                        : "");
                    return text == " | " ? "-" : text;
                  }
                  return "-";
                }}
              />
              <DataFieldColumn
                label="Proposition hôtel"
                type="text"
                value={chantier.propositionHotel}
              />

              <DataFieldColumn
                label="Nom hôtel"
                type="text"
                value={chantier.nomHotel}
              />
              <DataFieldColumn
                label="Localisation hôtel"
                type="text"
                value={chantier.localisationHotel}
              />
              <DataFieldColumn
                label="Hôtel prévenu"
                type="text"
                value={chantier.hotelPrevenu}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn label="Prix" type="text" value={chantier.prix} />

              <DataFieldColumn
                label="CB"
                type="text"
                value={chantier.cb}
                functionAppliedToValue={(value) => (value ? value.name : "-")}
              />
              <DataFieldColumn
                label="A annuler"
                type="text"
                value={chantier.annulation}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
            </div>
            <div className="col">
              <DataFieldColumn
                label="Montant remboursé 1"
                type="text"
                value={chantier.montantRembourse1}
              />
              <DataFieldColumn
                label="Montant remboursé 2"
                type="text"
                value={chantier.montantRembourse2}
              />
              <DataFieldColumn
                label="Supplément 1"
                type="text"
                value={chantier.supplement1}
              />
              <DataFieldColumn
                label="Supplément 2"
                type="text"
                value={chantier.supplement2}
              />

              <DataFieldColumn
                label="Petit déjeuner"
                type="text"
                value={chantier.petitDej}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Petit déjeuner validé"
                type="text"
                value={chantier.petitDejValide}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Soirée étape"
                type="text"
                value={chantier?.soireeEtape}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Soirée étape validé"
                type="text"
                value={chantier.soireeEtapeValide}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Parking"
                type="text"
                value={chantier.parking}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Parking validé"
                type="text"
                value={chantier.parkingValide}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <DataFieldColumn
                label="Commentaire"
                type="text"
                value={chantier.commentaire}
              />
            </div>
            <div className="col-6">
              <DataFieldColumn
                label="Commentaire hôtel"
                type="text"
                value={chantier.commentaireHotel}
              />
            </div>
          </div>
        </div>
      }
    ></Box>
  );

  let buttonAddPeriode = (
    <ButtonIconWithSmall
      icon={faPlus}
      className="btn btn-success text-light ms-1"
      onClick={() => {
        addNewPeriodeLocal();
        if (!editing) {
          setSavedChantier({ ...chantier });
          setEditing(true);
        }
      }}
      type="button"
    />
  );

  let box2 = generatePeriodesBox();
  box = page == 1 ? box : box2;
  return (
    <div className="row">
      <div
        className="FlecheGauche col-"
        style={{
          alignContent: "center",
          textAlign: "center",
          flex: "0 0 auto",
          width: "4%",
        }}
        onClick={() => setPage(1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={page == 1 ? "#c2bfbf" : "#000000"}
          height="200px"
          width="40px"
          version="1.1"
          id="Capa_1"
          viewBox="0 0 490 490"
        >
          <g>
            <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507   " />
          </g>
        </svg>
      </div>
      <div className="col-11">
        <FormWithValidation
          className="d-flex flex-grow-1"
          onSubmit={handleUpdate}
        >
          {box}
        </FormWithValidation>
      </div>
      <div
        className="Fleche droite"
        style={{
          alignContent: "center",
          textAlign: "center",
          flex: "0 0 auto",
          width: "4%",
        }}
        onClick={() => setPage(2)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={page == 2 ? "#c2bfbf" : "#000000"}
          height="200px"
          width="40px"
          version="1.1"
          id="Capa_1"
          viewBox="0 0 490 490"
          transform="rotate(180)"
        >
          <g>
            <polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507 " />
          </g>
        </svg>
      </div>
    </div>
  );
};

export { BlocChantier };
